//
import PProviderService from "@/services/api/Provider/PProvider";
import GCategoryDocumentService from "@/services/api/General/GCategoryDocument";
import RulesService from "@/services/rules";

//
export default {
  name: "v-view-provider",
  data() {
    return {
      busy: {
        create: false,
        delete: false,
        update: false,
        provider: false,
      },
      dialogs: {
        create: false,
        delete: false,
        update: false,
      },
      pProvider: {},
      pProviderDocument: {},
      pProviderDocumentC: {},
      pProviderDocumentUpdate: {
        g_provider_document_type_id: 1,
      },
      pProviderDocumentReset: {},
      pProviderDocumentDeleteId: null,
      pProviderDocuments: {
        data: [],
      },
      pProviderDocumentsSC: {
        data: [],
      },
      categoryDocuments: {
        data: [],
      },
      // categoryDocuments: [
      //   { id: 1, name: "Sin categoría", description: "" },
      //   {
      //     id: 2,
      //     name: "Finanzas",
      //     description:
      //       "Los requisitos Financieros - Tributarios son considerados obligatorios dado que evidencian la situación de la empresa en un determinado momento del tiempo. Dicha información permite generar análisis en relación a las proyecciones futuras de la empresa y tomar las decisiones según el escenario. Los requisitos Legales son considerados obligatorios dado que permiten constatar la vigencia de la empresa y su vinculación con la Región de Coquimbo.",
      //     documentsC: [
      //       {
      //         document_type: 2,
      //         cert: true,
      //         name: "Balance tributario",
      //         filename: "Balance tributario.pdf",
      //         description:
      //           "Balance Tributario:  Permite determinar en el último año el desempeño financiero de la empresa, su capacidad de generar ingresos.",
      //       },
      //       {
      //         document_type: 2,
      //         cert: true,
      //         name: "Cartola tributaria",
      //         filename: "Cartola tributaria.pdf",
      //         description:
      //           "Cartola Tributaria:  Permite entregar información de la empresa, dirección casa matriz regional, propiedades, resumen de boletas de honarios últimos 12 meses, 24 últimos periodos mensuales de IVA y 3 últimos periodos anuales de renta.",
      //       },
      //       {
      //         name: "Balance general clasificado",
      //         filename: "Balance general clasificado.pdf",
      //         description:
      //           "Balance General Clasificado:  Permite medir la capacidad de la empresa para hacer frente a sus obligaciones de corto plazo. ",
      //       },
      //       {
      //         document_type: 2,
      //         cert: true,
      //         name: "Estado de resultado",
      //         filename: "Estado de resultado.pdf",
      //         description:
      //           "Balance General Clasificado:  Permite medir la capacidad de la empresa para hacer frente a sus obligaciones de corto plazo. ",
      //       },
      //       {
      //         document_type: 2,
      //         cert: true,
      //         name: "Certificado de deuda tributaria",
      //         filename: "Certificado de deuda tributaria.pdf",
      //         description:
      //           "Certificado de Deuda Tributaria: Permite conocer y acreditar el estado de las deudas fiscales de la empresa. ",
      //       },
      //       {
      //         document_type: 2,
      //         cert: true,
      //         name: "Certificado de vigencia",
      //         filename: "Certificado de vigencia.pdf",
      //         description:
      //           "Certificado de vigencia: Permite identificar si la persona jurídica se encuentra vigente o disuelta.",
      //       },
      //       {
      //         document_type: 2,
      //         cert: true,
      //         name: "Patente comercial",
      //         filename: "Patente comercial.pdf",
      //         description:
      //           "Patente Comercial: Permite verificar que el domicilio registrado por el contribuyente ante el Servicio de Impuestos Internos corresponda al lugar donde desarrolla sus funciones en la Región de Coquimbo.",
      //       },
      //     ],
      //   },
      //   {
      //     id: 3,
      //     name: "Colaboradores",
      //     description:
      //       "Los requisitos de Colaboradores obligatorios consideran el cumplimiento de las normas legales, en cuanto a la contratación de un colaborador y los respectivos pagos obligatorios por parte de la empresa, tales como pago de cotizaciones, seguros de cesantia entre otros. Además, estos requisitos consideran la inclusión de conceptos relevantes como la equidad de género e inclusión como así tambien prevenir, detectar y solucionar situaciones de acoso en la organización.",
      //     documentsC: [
      //       {
      //         document_type: 3,
      //         cert: true,
      //         name: "Declaración jurada simple",
      //         filename: "Declaración jurada simple.pdf",
      //         description:
      //           "Declaración Jurada Simple: Permite verificar la regularización de la relación contractual de la empresa con los trabajadores.",
      //       },
      //       {
      //         document_type: 3,
      //         cert: true,
      //         name: "Certificado de cumplimiento de obligaciones lavorales y previcionales",
      //         filename:
      //           "Certificado de cumplimiento de obligaciones lavorales y previcionales.pdf",
      //         description:
      //           "Certificado de Cumplimiento de Obligaciones Laborales y Previcionales: Permite verificar el pago de las cotizaciones previsionales y de salud de los colaboradores",
      //       },
      //       {
      //         document_type: 3,
      //         cert: true,
      //         name: "Certificado de afiliación al instituo de seguridad laboral",
      //         filename:
      //           "Certificado de afiliación al instituo de seguridad laboral.pdf",
      //         description:
      //           "Certificado de afiliación al Instituto de Seguridad Laboral: Permite verificar la afiliación al seguro de cesantia de los colaboradores",
      //       },
      //       {
      //         document_type: 3,
      //         cert: true,
      //         name: "Certificado de cotizaciones",
      //         filename: "Certificado de cotizaciones.pdf",
      //         description:
      //           "Certificado de cotizaciones: Permite verificar el pago del seguro de cesantia de los colaboradores.",
      //       },
      //       {
      //         document_type: 3,
      //         cert: true,
      //         name: "Declaración de política de diversidad de género",
      //         filename: "Declaración de política de diversidad de género.pdf",
      //         description:
      //           "Declaración de política de Diversidad de género:Permite verificar que la empresa cuenta con una política declarada sobre la diversidad de género e inclusión.",
      //       },
      //       {
      //         document_type: 3,
      //         cert: true,
      //         name: "Declaración de política de bienestar",
      //         filename: "Declaración de política de bienestar.pdf",
      //         description:
      //           "Declaración de política de bienestar: Permite contar con un protocolo de acción frente a situaciones de acoso y resolución de problemas.",
      //       },
      //       {
      //         document_type: 3,
      //         cert: true,
      //         name: "Código de ética y canal de denuncia",
      //         filename: "Código de ética y canal de denuncia.pdf",
      //         description:
      //           "Código de ética y canal de denuncia: Permite contar con un código de ética en la empresa y disponer a los colaboradores de un canal de denuncias anónimas en caso de vivir o ser testigos de una situación de esta índole",
      //       },
      //       {
      //         document_type: 3,
      //         cert: true,
      //         name: "Contratación regional",
      //         filename: "Contratación regional.pdf",
      //         description:
      //           "Contratación Regional:  Permite medir el porcentaje de colaboradores que pertenecen a la región de Coquimbo con respecto a la totalidad de colaboradores de la empresa. ",
      //       },
      //       {
      //         document_type: 3,
      //         cert: true,
      //         name: "Contratación de mujeres",
      //         filename: "Contratación de mujeres.pdf",
      //         description:
      //           "Contratación de Mujeres:  Permite medir el porcentaje de colaboradores de sexo femenino con respecto a la totalidad de colaboradores de la empresa.  ",
      //       },
      //     ],
      //   },
      //   {
      //     id: 4,
      //     name: "Seguridad",
      //     description:
      //       "Los requisitos de Seguridad obligatorios consideran la declaración del compromiso con la seguridad,  afiliación a un organismo administrador, pagos de cotizaciones al día. Registros sobre siniestralidad, Plan de Prevención de Riesgos, estructura organizacional. Identificación de peligros, riesgos y controles. Cumplimiento de requisitos legales, procedimientos ante situaciones de emergencia. Los requisitos voluntarios contemplan las certificaciones de seguridad y salud según estándar ISO o similar (OHSAS).",
      //     documentsC: [
      //       {
      //         document_type: 4,
      //         cert: true,
      //         name: "Certificado de afiliación al instituto de seguridad laboral",
      //         filename:
      //           "Certificado de afiliación al instituto de seguridad laboral.pdf",
      //         description:
      //           "Certificado de afiliación al Instituto de Seguridad Laboral: Permite verificar el cumplimiento de las responsabilidades de la empresa en términos las obligaciones de Seguridad y Salud hacia sus trabajadores.",
      //       },
      //       {
      //         document_type: 4,
      //         cert: true,
      //         name: "Tasa de siniestralidad",
      //         filename: "Tasa de siniestralidad.pdf",
      //         description:
      //           "Documento que permita evidenciar la efectividad de los mecanismos de prevención de riesgos y accidentes laborales de los trabajadores de la empresa.",
      //       },
      //     ],
      //   },
      //   {
      //     id: 5,
      //     name: "Medio ambiente",
      //     description:
      //       "Los requisitos de Medio Ambiente obligatorios consideran el cumplimiento de legal ante residuos (RISES, RILES y agua), emisiones, contaminantes, sustancias peligrosas, tener un Plan Medioambiental, entre otros. Los requisitos voluntarios contemplan las certificaciones de medio ambiente según estándar ISO u otro (Acuerdo Producción Limpia, aporte a los ODS).",
      //     documentsC: [
      //       {
      //         document_type: 5,
      //         cert: true,
      //         name: "Plan mediambiental",
      //         filename: "Plan mediambiental.pdf",
      //         description:
      //           "Documento con Plan Medioambiental: Permite evidenciar que la empresa posee un plan que establezca las acciones que se requieren para: prevenir, mitigar, controlar, compensar y corregir los posibles efectos sobre el medio ambiente, producto de sus labores.",
      //       },
      //       {
      //         document_type: 5,
      //         cert: true,
      //         name: "Gestión de sostenibilidad",
      //         filename: "Gestión de sostenibilidad.pdf",
      //         description:
      //           "Documento que permita evidenciar el nivel de compromiso, planificación y acción de la empresa en términos de la Sostenibilidad, así como el nivel de contribución a los Objetivos de Desarrollo Sostenibles, declarados en la Agenda 2030.",
      //       },
      //       {
      //         document_type: 5,
      //         cert: true,
      //         name: "Certificado de cumplimiento del APL",
      //         filename: "Certificado de cumplimiento del APL.pdf",
      //         description:
      //           "Certificado de Cumplimiento del APL: Permite evidenciar el cumplimiento del Acuerdo de Producción Limpia.",
      //       },
      //     ],
      //   },
      //   {
      //     id: 6,
      //     name: "Calidad",
      //     description:
      //       "Los requisitos de Calidad obligatorios consideran la declaración del compromiso con la calidad, certificaciones obligatorias según aplique, garantías, acciones correctivas y evidencias de la conformidad del mandante con el producto y/o servicio recibido. Los requisitos voluntarios contemplan las certificaciones de calidad según estándar ISO y/o Norma Chilena.",
      //     documentsC: [
      //       {
      //         document_type: 6,
      //         cert: true,
      //         name: "Política de calidad",
      //         filename: "Política de calidad.pdf",
      //         description:
      //           "Política de Calidad - Manual del Sistema de Gestión de la Calidad: Permite evidenciar que existe un compromiso declarado por la empresa en relación a la calidad y a la satisfacción de sus clientes.",
      //       },
      //       {
      //         document_type: 6,
      //         cert: true,
      //         name: "Certificación sanitaria",
      //         filename: "Certificación sanitaria.pdf",
      //         description:
      //           "Certificados que permitan verificar que la empresa cumple los requisitos sanitarios, fitisanitarios y/o eléctricos. (En caso que aplique)",
      //       },
      //       {
      //         document_type: 6,
      //         cert: true,
      //         name: "Conformidad con el producto",
      //         filename: "Conformidad con el producto.pdf",
      //         description:
      //           "Documentos de medición al Cliente: Permite evidenciar que la empresa mide, registra y se retroalimenta de sus clientes en relación a la calidad del producto / servicio que otorga.",
      //       },
      //     ],
      //   },
      //   {
      //     id: 7,
      //     name: "Vinculación",
      //     description:
      //       "Los requisitos de Vinculación contemplan la contratación de proveedores regionales por parte de la empresa, la vinculación e/o inversión social, mostrar evidencia de la inversión en territorio local regional. De manera voluntaria la adquisión de certificaciones en el ámbito de la Responsabilidad Social, tales como ISO, Sello Pro Pyme, entre otras.",
      //     documentsC: [
      //       {
      //         document_type: 7,
      //         cert: true,
      //         name: "Pago de patente comercial",
      //         filename: "Pago de patente comercial.pdf",
      //         description:
      //           "Pago de patente comercial: Permite evidenciar la capacidad instalada de la organización en el territorio. ",
      //       },
      //       {
      //         document_type: 7,
      //         cert: true,
      //         name: "Plan de capacitación",
      //         filename: "Plan de capacitación.pdf",
      //         description:
      //           "Plan y/o programas de capacitación al Recurso Humano: Permite verficar el desarrollo de destrezas en áreas organizacionales fundamentales, como el servicio al cliente, ventas, comunicación, entre otros. ",
      //       },
      //       {
      //         document_type: 7,
      //         cert: true,
      //         name: "Contratación de proveedores regionales",
      //         filename: "Contratación de proveedores regionales.pdf",
      //         description:
      //           "Contratos de proveedores regionales: Permite evidenciar a través de contratos a proveedores regionales (adquisión de insumos). ",
      //       },
      //       {
      //         document_type: 7,
      //         cert: true,
      //         name: "Vínculos o inversión social",
      //         filename: "Vínculos o inversión social.pdf",
      //         description:
      //           "Programa de Apoyo Comunitario y/o Declaración jurada: Permite evidenciar el compromiso con el desarrollo comunitario. ",
      //       },
      //       {
      //         document_type: 7,
      //         cert: true,
      //         name: "Vinculos educacionales regionales",
      //         filename: "Vinculos educacionales regionales.pdf",
      //         description:
      //           "Convenios existentes: Permite verificar que la empresa cumpla con los vinculos correspondientes a institutos educacionales regionales a través de convenios. ",
      //       },
      //       {
      //         document_type: 7,
      //         cert: true,
      //         name: "Sello pro pyme",
      //         filename: "Sello pro pyme.pdf",
      //         description:
      //           "Sello Pro Pyme: Permite verificar el cumplimiento con las exigencias del sello.",
      //       },
      //     ],
      //   },
      // ],
      pDocuments: [
        { document_type: 1, name: "Sin tipo" },
        { document_type: 2, name: "Certificado de vigencia" },
        { document_type: 3, name: "Carpeta tributaria" },
        { document_type: 4, name: "Certificado de deuda fiscal" },
        {
          document_type: 5,
          name: "Declaración jurada simple lista trabajadores",
        },
        { document_type: 6, name: "Certificado de AFC" },
        {
          document_type: 7,
          name: "Certificado de cumplimiento de obligaciones laborales",
        },
        { document_type: 8, name: "Código Ética" },
        { document_type: 9, name: "Certificado afiliación" },
        { document_type: 10, name: "Certificado de tasas seguridad" },
        { document_type: 11, name: "Política de calidad" },
      ],
    };
  },
  methods: {
    /**
     *
     */
    async handleCreateReset() {
      this.pProviderDocument = PProviderService.toDocument();
    },

    /**
     *
     */
    async handleCreateSubmit() {
      this.busy.create = true;
      console.log("pcrea", this.pProviderDocument);
      await PProviderService.document(this.pProvider, this.pProviderDocument)
        .then(this.handleSuccess)
        .catch(this.handleError)
        .finally(this.handleReset);
      this.busy.create = false;
      this.dialogs.create = false;
    },

    /**
     *
     */
    handleDeleteClick(pProviderDocumentDelete) {
      this.dialogs.delete = true;
      this.pProviderDocumentDeleteId = pProviderDocumentDelete.id;
    },

    /**
     *
     */
    async handleDeleteSubmit() {
      this.busy.delete = true;
      await PProviderService.documentDelete(
        this.pProvider,
        this.pProviderDocumentDeleteId
      )
        .then(this.handleSuccess)
        .catch(this.handleError)
        .finally(this.handleReset);
      this.busy.delete = false;
    },

    /**
     *
     */
    async handleDownloadClick(pProviderDocument) {
      this.busy.provider = true;
      await PProviderService.documentDownload(
        this.pProvider,
        pProviderDocument
      );
      this.busy.provider = false;
    },

    /**
     *
     */
    async handleReset() {
      this.busy.provider = true;
      this.categoryDocuments = await GCategoryDocumentService.getAll();
      console.log("this.categoryDocuments", this.categoryDocuments);
      this.pProvider = await PProviderService.getById(this.$provider.id);
      this.pProviderDocuments = await this.pProvider
        .many("document")
        .query()
        .param("get_all_results", "true")
        .param("filter[g_provider_document_type_id]", "2,3,4,5,6,7,8,9,10,11")
        .get();
      this.pProviderDocumentsSC = await this.pProvider
        .many("document")
        .query()
        .param("get_all_results", "true")
        .param("filter[g_provider_document_type_id]", "1")
        .get();
      console.log("this.pProviderDocumentsSC", this.pProviderDocumentsSC);
      this.busy.provider = false;
    },

    /**
     *
     */
    handleUpdateClick(pProviderDocumentUpdate) {
      this.dialogs.update = true;
      this.pProviderDocumentReset = { ...pProviderDocumentUpdate };
      this.pProviderDocumentUpdate = { ...pProviderDocumentUpdate };
    },

    /**
     *
     */
    handleUpdateReset() {
      this.pProviderDocumentUpdate = { ...this.pProviderDocumentReset };
    },

    async handleCategoryDocument() {
      this.categoryDocuments = await GCategoryDocumentService.getAll();
    },

    /**
     *
     */
    async handleUpdateSubmit() {
      this.busy.update = true;
      // console.log("pupdate", this.pProviderDocumentUpdate);
      await PProviderService.documentUpdate(
        this.pProvider,
        this.pProviderDocumentUpdate
      )
        .then(this.handleSuccess)
        .catch(this.handleError)        
        .finally(this.handleReset);
      this.busy.update = false;
    },

    /**
     *
     */
    handleError(payload) {
      PProviderService.translate(payload).then(this.$toast.error);
    },

    /**
     *
     */
    handleSuccess(payload) {
      this.dialogs.create = false;
      this.dialogs.delete = false;
      this.dialogs.update = false;
      this.handleReset();
      PProviderService.translate({ response: payload }).then(
        this.$toast.success
      );
    },
  },
  created() {
    this.handleReset();
    this.handleCreateReset();
  },
  props: {
    /**
     *
     */
    fileRules: {
      type: Array,
      default: () => [RulesService.required],
    },

    /**
     *
     */
    nameRules: {
      type: Array,
      default: () => [RulesService.required, RulesService.max(200)],
    },
  },
};
